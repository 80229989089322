import React from "react"
import PropTypes from "prop-types"

import Header from "./header"

import * as styles from "./layout.module.css"

const Layout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Header />
      <main>
        {children}
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
